import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A modul beágyazásához az integráló fél weboldalán az alábbi `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` HTML elemet kell elhelyezni:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<iframe 
  title=”Kockázatelemzés”
  src=”DOMAIN?partner-key=PARTNER_KEY&payload=PAYLOAD”
>
`}</code></pre>
    <p>{`Az `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` használatához szükség van a forrás címére, amit a Péntech szolgál ki.
Ez több részből tevődik össze, amiben a használathoz az integráló félnek
minden attribútumot meg kell adnia.`}</p>
    <h2>{`Domain`}</h2>
    <p>{`A Péntech 2 környezetet bocsájt az integráló rendelkezésére, amiket az alábbi címeken érhetőek el:`}</p>
    <h3>{`Demo (tesztkörnyezet)`}</h3>
    <p>{`A partner által tesztelésre használható környezet, ami az éles környezettől teljesen függetlenül fut. Ebben a környezetben a rendszer összes stabil és publikálásra jelölt (release candidate) verziója automatikusan frissül.`}</p>
    <p>{`Ennek címe: `}<inlineCode parentName="p">{`https://quickrisk.module.demo.pentech.hu`}</inlineCode>{` `}</p>
    <h3>{`Production (éles környezet)`}</h3>
    <p>{`Az ügyfelek által használt weboldalon használt környezet, ami a demo környezettől teljesen függetlenül fut. Ebben a környezetben a rendszer csak a stabil verziókra frissül.`}</p>
    <p>{`Ennek címe: `}<inlineCode parentName="p">{`https://quickrisk.module.pentech.hu`}</inlineCode>{` `}</p>
    <h2>{`partner-key`}</h2>
    <p>{`Integráló fél azonosítója a Péntech rendszerében.`}</p>
    <h2>{`payload`}</h2>
    <p>{`A végfelhasználó azonosítására szolgáló azonosítókat tartalmazó objektum kódolt formája.`}</p>
    <h3>{`Struktúra`}</h3>
    <p>{`2 elemet tartalmaz, mindkettő értéke szöveg típusú.`}</p>
    <p>{`A ”`}<strong parentName="p">{`session`}</strong>{`” attribútum a végfelhasználó munkamenetének azonosítója. Ez főleg a nem bejelentkezett felhasználók lekéréseinek limitálására szolgál, de segít a riportálásban összekötni azokat az eseteket, amikor egy felhasználó lekérdez egy vagy több elemzést, majd belép az integráló rendszerébe és további lekérdezéseket futtat. `}</p>
    <p>{`Ajánlott az integráló rendszer munkamenet azonosítására használt azonosító használata erre a célra is. (Tehát ha van sessionId, akkor azt direktben fel lehet erre is használni)`}</p>
    <p>{`A ”`}<strong parentName="p">{`user`}</strong>{`” opcionális (elhagyható) attribútum a bejelentkezett felhasználó lekérdezéseinek összekapcsolására, és a bejelentkezett felhasználók lekérdezési limit számolására felhasznált érték.`}</p>
    <p>{`A fenti attribútumok mentésre kerülnek és a kimutatásokban megjelenhetnek.`}</p>
    <h3>{`Példa`}</h3>
    <p>{`Ebben a szekcióban egy példán keresztül mutatjuk be a `}<strong parentName="p">{`payload`}</strong>{` kódolását.`}</p>
    <p>{`Eredeti, kódolatlan objektum:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "session": "93b4",
    "user": "9542"
}
`}</code></pre>
    <p>{`Ezt a JSON objektumot `}<strong parentName="p">{`AES-256-CBC`}</strong>{` titkosítással a Péntech által megadott titkosító kulcs segítségével kell kódolni:`}</p>
    <p>{`Kulcs: `}<inlineCode parentName="p">{`"ee9aec3feff3a0496f18327251649aec"`}</inlineCode>{` (Előre egyeztetett)`}<br parentName="p"></br>{`
`}{`Inicializáló vektor (~salt): `}<inlineCode parentName="p">{`"012be27fbbb413d2"`}</inlineCode>{` (Kódoláskor generált)`}</p>
    <p>{`Ezekkel a kulcsokkal, a fenti objektum elkódolva, a vektorral ”`}<inlineCode parentName="p">{`:`}</inlineCode>{`” (kettőspont) karakterrel összefűzve:`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`rpeo/GeYN5K6a0Axv/W0fJDdQOWYP5SUDAk/tquuHFYxUhtxAZ/XyeVvZ+KV5NH3:012be27fbbb413d2`}</inlineCode></p>
    <p>{`Mely ez után RFC 3986 szabvány szerinti URL komponensé alakítva:`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`rpeo%2FGeYN5K6a0Axv%2FW0fJDdQOWYP5SUDAk%2FtquuHFYxUhtxAZ%2FXyeVvZ%2BKV5NH3%3A012be27fbbb413d2`}</inlineCode></p>
    <p>{`Az így kapott URL:`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`https://quickrisk.module.pentech.hu?partner-key=nlo4Xf1dn-nak&payload=rpeo%2FGeYN5K6a0Axv%2FW0fJDdQOWYP5SUDAk%2FtquuHFYxUhtxAZ%2FXyeVvZ%2BKV5NH3%3A012be27fbbb413d2`}</inlineCode></p>
    <p>{`Az `}{`<`}{`iframe`}{`>`}{` elemnek ezt a generált URL-t kell megadni az `}<inlineCode parentName="p">{`src`}</inlineCode>{` attribútumban.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      